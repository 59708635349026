@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.companyCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: 0 16px 20px 0 rgba(36, 85, 158, 0.08);
}

.companyName {
  margin-top: 16px;
  @include body-text('md');
}

// Plus icon
.container::after {
  content: '+';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black6;
  border: 1px solid $black10;
  border-radius: 50%;
  font-size: 24px;
  font-weight: $boldFontWeight;
}

.companyCard svg {
  width: 48px;
  height: 48px;
}
